// Here you can add other styles
.fs-12 {
    font-size: 12px;
}

.overflow-scroll {
    overflow: scroll;
}

.overflow-x-scroll {
    overflow-x: scroll;
}

.overlow-y-scroll {
    overflow-y: scroll;
}

// Link {
//     text-decoration: none;
// }

.bg-light {
    background-color: #fff !important;
}

.bg-grey {
    background-color: #eee;;
}